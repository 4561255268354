/* global Component */
class WhyUsPage extends Component{

    static  name() {
        return "whyUsPage";
    }

    static componentName() {
        return "whyUsPage";
    }


    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">Por qué Ofidirect?</h5>
                          <hr>
                          <div class="row">
                              <div class="col-12 col-md-6">
                                  <p>Durante la última década Ofidirect ha evolucionado de ser un proveedor de insumos de oficina a ser un socio estratégico en las necesidades diarias del negocio de nuestros clientes.</p>
                                  <p>Esto significa que cuando decide trabajar con Ofidirect, su empresa automáticamente gana un socio, con una gran infraestructura que lo beneficiará con los precios más bajos del mercado, ya que aprovechará el gran poder de negociación de Ofidirect con sus proveedores.</p>
                                  <p>Por otro lado, accederá a una red logística que le permitirá ser abastecido en cualquier punto del país. Y toda la tecnología para que pueda realizar sus pedidos con facilidad y en el momento en que usted lo desee.</p>
                              </div>
                              <div class="col-12 col-md-6 text-center text-md-left">
                                  <img class="img-fluid" src="../static/ecommerce/img/ofi_002.jpg">
                              </div>
                          </div>
                          <div class="mt-2 mb-4">
                              <strong>Calidad en servicio sin importar el tamaño de la empresa</strong>
                              <p>En Ofidirect ayudamos a nuestros clientes en los negocios de todos los días brindándoles los insumos y servicio que necesitan en el lugar que corresponde: en su oficina. Ofidirect pone a su alcance herramientas para ahorrarle tiempo y dinero.</p>
                              <strong>Ofidirect Pequeños Negocios</strong>
                              <p>Les ofrecemos atención personalizada y la misma gran variedad de productos que a nuestros mayores clientes, a un precio inigualable.</p>
                          </div>
                          <div class="row">
                              <div class="col-12 col-md-6 text-center text-md-right">
                                  <img class="img-fluid" src="../static/ecommerce/img/ofi_007.jpg">
                              </div>
                              <div class="col-12 col-md-6">
                                  <strong>Ofidirect Pequeña y Mediana Empresa</strong>
                                  <p>Alguna de las razones por la cual no se arrepentirá de hacer negocios con nosotros:</p>
                                  <ul>
                                    <li>Atención personalizada. Obtenga soluciones hablando con un ejecutivo de cuenta altamente capacitado que realmente entiende las necesidades de su negocio.</li>
                                    <li>Precios bajos todos los días. Gracias a nuestro gran poder de compra le ofrecemos la mejor calidad al mejor precio todos los días.</li>
                                    <li>Facilidad para realizar los pedidos. Haga su pedido por teléfono, fax o Internet, y se lo enviamos gratis  para que no tenga que ir a la librería de la esquina.</li>
                                  </ul>
                                  <strong>Ofidirect Grandes Empresas</strong>
                                  <p>Ayudarlo a reducir sus costos es la base de nuestro servicio. Contratos a medida y soluciones individuales lo ayudaran a reducir costos en la compra de productos de oficina.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }

}


WhyUsPage.registerComponent();
